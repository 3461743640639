
<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{}"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="false"
      :addable="false"
      :linePrefix="getFieldId(fieldAttributes.fieldAttrInput, result, field)"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import { getFieldId } from "@/js/helper.js";
export default {
  props: ["result", "fieldAttributes","value","field"],
  components: { lineFieldsArray },
  data() {
    return {};
  },
  
  computed: {
    fields() {
      let fields = {
        index: {
          type: "text",

          name: "index",
        },
        value: {
          type: "text",

          name: "Value",
        },
        remove: lineButtons.remove,
        add: lineButtons.add,
      };
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        if (
          typeof this.value != "undefined" &&
          Object.keys(this.value).length != 0
        ) {
          for (const [key, value] of Object.entries(
            this.value
          )) {
            pairArray.push({ index: key, value: value });
          }
        } else {
          pairArray = [{ "": "" }];
        }
        return pairArray;
      },
      set(newValue) {
        // console.log("new value", newValue);
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
     getFieldId: getFieldId,
    checkInput(value) {
      //console.log(value);
      let sentValue = {};
      let update = true;
      let caller = this;
      value.forEach(function (info) {
        if (typeof sentValue[info.index] != "undefined") {
          update = false;
          return;
        }
        caller.$set(sentValue, info.index, info.value);
      });
      if (update) {
        this.$emit("input", sentValue);
      }
    },
  },
};
</script>